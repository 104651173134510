<template>
    <div>
        <!--<CustomDataTable :table-title="tableTitle" :endpoint="endpoint" :filters="filters" :table-fields="tableFields" :buttons="buttons" v-on:show-data-modal="showDataRowModal"></CustomDataTable>-->
        <CustomDataTable :table-title="tableTitle" :endpoint="endpoint" :filters="filters" :filters-storage-key="filtersStorageKeyPrefix + filtersStorageKey" v-bind:reset-local-storage-filters-handler="resetLocalStorageFiltersHandler" :table-fields="tableFields" :search-filters="searchFilters" :buttons="buttons" :items-func="navigateToUser"  ref="table" v-if="endpoint"></CustomDataTable>
        <!--<DataRowModal :dialog="dialog" :data="rowData" @hide-data-modal="hideDataRowModal" :fields="rowFields"></DataRowModal>-->
    </div>
</template>

<script>
    //import Vue from 'vue';
    import CustomDataTable from "../../../components/table/CustomDataTable";
    /*import DataRowModal from "../../../components/table/DataRowModal";*/
    export default {
        name: "Users_view",
        //components: {DataRowModal, CustomDataTable},
        components: {CustomDataTable},
        data: ()=>({
            tableTitle: 'EPB Users',
            endpoint: null,
            routeParams: null,
            filters:{},
            filtersStorageKeyPrefix: null,
            filtersStorageKey: null,
            localStorageFilters: null,
            tableFields: [],
            searchFilters: [],

            buttons:[],
            //userId: null,
        }),
        methods:{
            loadLocalStorageFilters: async function (key) {
                let localStorageFilters = await this.$globalHelpers.getLocalStorageItem(this.filtersStorageKeyPrefix + key, null);
                if (localStorageFilters) {
                    this.localStorageFilters = JSON.parse(localStorageFilters);
                }
            },
            resetLocalStorageFiltersHandler: function (){
                this.initialize(true);
            },
            initialize: async function (loadList) {
                console.log(this.routeParams);
                this.filters = {
                    RoleIds: null,
                    DesignationIds: null,
                    ExporterTypes: null,
                    ServiceOfficeIds: null,
                    Email: null,
                    Username: null,
                    Name: null,
                    OrganizationName: null,
                    Skip: 0,
                    Take: 10,
                };
                this.filtersStorageKey = null;
                this.localStorageFilters = null;
                this.tableFields = [];
                this.searchFilters = [];
                this.buttons = [];

                this.filtersStorageKeyPrefix = this.$globalHelpers.getAuthStoreValue('user_id');
                this.prepareTableFields();
                this.prepareSearchFilters();
                await this.checkUserActionPermission();

                if (loadList) {
                    await this.$refs.table.loadList();
                } else {
                    this.endpoint = this.$globalSettings.api.endpoints.user.list;
                }
            },
            prepareTableFields: function (){
                this.tableFields = [
                    {key: 'Email', label: 'Email', type: 'string'},
                    {key: 'Name', label: 'Name', type: 'string'},
                    {key: 'DesignationId', label: 'Designation', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.Designations)},
                    {key: 'OrganizationName', label: 'Organization', type: 'string'},
                    {key: 'Username', label: 'Username', type: 'string'},
                    {key: 'Mobile', label: 'Mobile', type: 'string'},
                    //{key: 'Phone', label: 'Phone', type: 'string'},
                    {key: 'ExporterType', label: 'Department', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes)},
                    {key: 'ServiceOfficeId', label: 'Office', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices)},
                    //{key: 'Created', label: 'Created', type: 'date'},
                    {key: 'Modified', label: 'Updated', type: 'date'},
                ];
            },
            prepareSearchFilters: function (){
                this.searchFilters = [
                    {key: 'Email', label: 'Email', type: 'number'},
                    {key: 'Username', label: 'Username', type: 'number'},
                    {key: 'Name', label: 'Name', type: 'number'},
                    {key: 'OrganizationName', label: 'Organization', type: 'string'},
                    //{key: 'ExporterType', label: 'Department', type: 'arrayFilter', filterKey: 'ExporterTypes', array: this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes), excludes: []},
                    {key: 'ServiceOfficeId', label: 'Service Office', type: 'arrayFilter', filterKey: 'ServiceOfficeIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices), excludes: []},
                ];
            },
            checkUserActionPermission: async function () {
                this.filters.RoleIds = [];
                this.filters.DesignationIds = [];
                this.filters.ExporterTypes = [];
                this.buttons = [];
                let departments = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes);
                let roles = this.$globalHelpers.enumToArray(this.$globalEnums.roles);
                let designations = this.$globalHelpers.enumToArray(this.$globalEnums.Designations);
                if (this.$route.params.type === 'users') {
                    this.tableTitle = 'EPB Users';

                    this.filtersStorageKey = 'users';
                    await this.loadLocalStorageFilters(this.filtersStorageKey);
                    if (this.localStorageFilters) {
                        this.filters.RoleIds = this.localStorageFilters.RoleIds;
                        this.filters.ExporterTypes = this.localStorageFilters.ExporterTypes;
                        this.filters.DesignationIds = this.localStorageFilters.DesignationIds;
                    }else{
                        this.filters.RoleIds = [this.$globalEnums.roles.Admin, this.$globalEnums.roles.DA, this.$globalEnums.roles.Enrollment_Executive, this.$globalEnums.roles.AD, this.$globalEnums.roles.DD, this.$globalEnums.roles.Director, this.$globalEnums.roles.DG];
                        this.filters.ExporterTypes = [this.$globalEnums.ExporterTypes.None, this.$globalEnums.ExporterTypes.Textile, this.$globalEnums.ExporterTypes.NonTextile, this.$globalEnums.ExporterTypes.Commodities, this.$globalEnums.ExporterTypes.Administration, this.$globalEnums.ExporterTypes.Fair, this.$globalEnums.ExporterTypes.Information, this.$globalEnums.ExporterTypes.Finance, this.$globalEnums.ExporterTypes.Project];
                        this.filters.DesignationIds = [this.$globalEnums.Designations.None, this.$globalEnums.Designations.Dealing_Assistant, this.$globalEnums.Designations.Enrollment_Executive, this.$globalEnums.Designations.Assistant_Director, this.$globalEnums.Designations.Deputy_Director, this.$globalEnums.Designations.Programmer, this.$globalEnums.Designations.Director, this.$globalEnums.Designations.Secretary, this.$globalEnums.Designations.Director_General, this.$globalEnums.Designations.Vice_Chairman, this.$globalEnums.Designations.Deputy_Secretary, this.$globalEnums.Designations.Research_Officer, this.$globalEnums.Designations.Information_Officer, this.$globalEnums.Designations.Account_Officer, this.$globalEnums.Designations.Technical_Officer, this.$globalEnums.Designations.Investigator, this.$globalEnums.Designations.Computer_Operator, this.$globalEnums.Designations.UDA, this.$globalEnums.Designations.LDA, this.$globalEnums.Designations.Executive_Assistant, this.$globalEnums.Designations.Draftsman, this.$globalEnums.Designations.Receptionist, this.$globalEnums.Designations.Administration_Officer, this.$globalEnums.Designations.Project_Officer, this.$globalEnums.Designations.Sales_Officer, this.$globalEnums.Designations.Software_Admin];
                    }

                    this.searchFilters.push({key: 'RoleId', label: 'Role', type: 'arrayFilter', filterKey: 'RoleIds', array: roles.filter(x => x.id !== this.$globalEnums.roles.Exporter), excludes: []});
                    this.tableFields.push({key: 'RoleId', label: 'Role', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.roles)});

                    this.searchFilters.push({key: 'ExporterType', label: 'Department', type: 'arrayFilter', filterKey: 'ExporterTypes', array: departments, excludes: []});

                    this.searchFilters.push({
                        key: 'DesignationId',
                        label: 'Designation',
                        type: 'arrayFilter',
                        filterKey: 'DesignationIds',
                        array: designations.filter(x => x.id < 100),
                        excludes: []
                    });

                    //this.tableFields.push({key: 'DesignationId', label: 'Designation', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.Designations)});

                    if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Admin) {
                        this.buttons.push({
                            label: 'Add EPB User',
                            icon: 'fas fa-id-card',
                            type: 'nav',
                            func: this.navigateToCreateUser,
                            funcParam: 'epb'
                        });
                    }
                }
                if (this.$route.params.type === 'exporters') {
                    this.tableTitle = 'Exporter Users';

                    this.filtersStorageKey = 'exporters';
                    await this.loadLocalStorageFilters(this.filtersStorageKey);
                    if (this.localStorageFilters) {
                        this.filters.RoleIds = this.localStorageFilters.RoleIds;
                        this.filters.ExporterTypes = this.localStorageFilters.ExporterTypes;
                        this.filters.DesignationIds = this.localStorageFilters.DesignationIds;
                    }else{
                        this.filters.RoleIds = [this.$globalEnums.roles.Exporter];
                        this.filters.ExporterTypes = [this.$globalEnums.ExporterTypes.Textile, this.$globalEnums.ExporterTypes.NonTextile];
                        this.filters.DesignationIds = [this.$globalEnums.Designations.None, this.$globalEnums.Designations.Commercial_Officer, this.$globalEnums.Designations.Manager, this.$globalEnums.Designations.Managing_Director, this.$globalEnums.Designations.General_Manager, this.$globalEnums.Designations.Commercial_Executive, this.$globalEnums.Designations.Commercial_Manager, this.$globalEnums.Designations.Proprietor, this.$globalEnums.Designations.Chairman, this.$globalEnums.Designations.Dir];
                    }

                    this.searchFilters.push({
                        key: 'RoleId',
                        label: 'Role',
                        type: 'arrayFilter',
                        filterKey: 'RoleIds',
                        array: roles.filter(x => x.id === this.$globalEnums.roles.Exporter),
                        excludes: []
                    });

                    this.searchFilters.push({
                        key: 'ExporterType',
                        label: 'Department',
                        type: 'arrayFilter',
                        filterKey: 'ExporterTypes',
                        array: departments.filter(x => x.id === this.$globalEnums.ExporterTypes.None || x.id === this.$globalEnums.ExporterTypes.Textile || x.id === this.$globalEnums.ExporterTypes.NonTextile),
                        excludes: []
                    });

                    this.searchFilters.push({
                        key: 'DesignationId',
                        label: 'Designation',
                        type: 'arrayFilter',
                        filterKey: 'DesignationIds',
                        array: designations.filter(x => x.id == null || (x.id >= 100)),
                        excludes: []
                    });

                    this.buttons.push({
                        label: 'Add Exporter User',
                        icon: 'fas fa-id-card',
                        type: 'nav',
                        func: this.navigateToCreateUser,
                        funcParam: 'exporter'
                    });
                }

                if (this.localStorageFilters) {
                    this.filters.ServiceOfficeIds = this.localStorageFilters.ServiceOfficeIds;
                    //this.filters.Email = this.localStorageFilters.Email;
                    //this.filters.Username = this.localStorageFilters.Username;
                    //this.filters.Name = this.localStorageFilters.Name;
                    //this.filters.OrganizationName = this.localStorageFilters.OrganizationName;
                }else{
                    this.filters.ServiceOfficeIds = [
                        this.$globalEnums.ServiceOffices.None,
                        this.$globalEnums.ServiceOffices.Dhaka,
                        this.$globalEnums.ServiceOffices.Narayanganj,
                        this.$globalEnums.ServiceOffices.Chattogram,
                        this.$globalEnums.ServiceOffices.Cumilla,
                        this.$globalEnums.ServiceOffices.Khulna,
                        this.$globalEnums.ServiceOffices.Rajshahi,
                        this.$globalEnums.ServiceOffices.Sylhet,
                    ];
                }
            },
            navigateToCreateUser: async function (type) {
                await this.$router.push({name: 'App.User', params: {id: '0', type: type}});
            },
            navigateToUser: async function (id) {
                await this.$router.push({name: 'App.User', params: {id: id, type: this.$route.params.type === 'exporters'? 'exporter': 'epb'}});
            },
        },
        created() {
            this.endpoint = null;
            this.routeParams = this.$route.params;
            //this.endpoint = this.$globalSettings.api.endpoints.user.list;
            this.initialize(false);
            //console.log(this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices));
            //console.log(this.$globalHelpers.enumToArray(this.$globalEnums.Designations));
            this.$watch(
                () => this.$route.params,
                // eslint-disable-next-line no-unused-vars
                (toParams, previousParams) => {
                    this.routeParams = toParams;
                    this.initialize(true);
                }
            );
        }
    }
</script>

<style scoped>

</style>